import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { WootBlogPosts } from "./types";
import WootBlogPostCard from "./WootBlogPostCard";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";

interface WootBlogPostListProps {
  page_size: number;
}

const WootBlogPostList = ({ page_size }: WootBlogPostListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogPosts, setBlogPosts] = useState<WootBlogPosts | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchBlogPosts = useCallback(
    async (page: number) => {
      setError(null);
      setLoading(true);

      try {
        const response = await axios.get<WootBlogPosts>(
          `${appConfig.aura_url}/blog/items?page=${page}&page_size=${page_size}`,
          { headers: { accept: "application/json" } }
        );
        setBlogPosts(response.data);
      } catch (err) {
        const axiosError = err as AxiosError;
        const errorMessage =
          (axiosError.response?.data as string) ||
          "Failed to fetch blog posts. Please try again.";
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [page_size]
  );

  useEffect(() => {
    fetchBlogPosts(currentPage);
  }, [currentPage, fetchBlogPosts]);

  const handleNextPage = () => {
    if (blogPosts && currentPage < blogPosts.total_pages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        height: "100%",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: "20px",
          }}
        >
          <CircularProgress size={100} color="secondary" />
        </Box>
      ) : error ? (
        <Container maxWidth="xs">
          <Alert severity="error">{error}</Alert>
        </Container>
      ) : blogPosts && blogPosts.posts.length > 0 ? (
        <>
          {/* Blog Post List */}
          <Grid
            container
            spacing={4}
            direction="column"
            sx={{
              flex: 1,
              overflowY: "auto",
              pb: 1,
            }}
          >
            {blogPosts.posts.map((post) => (
              <Grid item key={post.id} xs={12}>
                <WootBlogPostCard
                  id={post.id}
                  title={post.title}
                  excerpt={post.excerpt}
                  image_url={post.image_url}
                  post_url={post.post_url}
                  created_at={post.created_at}
                />
              </Grid>
            ))}
          </Grid>

          {/* Pagination */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              sx={{ mx: 1 }}
            >
              Previous
            </Button>
            <Typography variant="body2" sx={{ alignSelf: "center", mx: 2 }}>
              Page {currentPage} of {blogPosts.total_pages}
            </Typography>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === blogPosts.total_pages}
              sx={{ mx: 1 }}
            >
              Next
            </Button>
          </Box>
        </>
      ) : (
        <Typography variant="h6" align="center" sx={{ mt: 4 }}>
          No blog posts found.
        </Typography>
      )}
    </Box>
  );
};

export default WootBlogPostList;
