import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActions,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { formatShortDate } from "../../utils/Dates";
import PlaceholderBox from "../PlaceholderBox";

interface WootBlogPostCardProps {
  id: string;
  title: string;
  excerpt: string;
  image_url: string;
  post_url: string;
  created_at: string;
}

const WootBlogPostCard = ({
  id,
  title,
  excerpt,
  image_url,
  created_at,
}: WootBlogPostCardProps) => {
  const [loading, setLoading] = useState(true); // Track loading state for image

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "flex-start",
        textAlign: "left",
        borderRadius: "10px",
      }}
      variant="outlined"
    >
      <CardMedia
        component="img"
        image={image_url}
        alt={title}
        sx={{
          width: { xs: 70, md: 140 },
          height: { xs: 70, md: 140 },
          marginLeft: 2,
          marginY: 2,
          objectFit: "cover",
          transition: "width 0.3s ease, height 0.3s ease",
        }}
        onLoad={() => setLoading(false)} // Image loaded, stop showing placeholder
        onError={() => setLoading(false)} // In case of error, hide placeholder
      />
      {loading && (
        <PlaceholderBox
          width={{ xs: 70, md: 140 }}
          height={{ xs: 70, md: 140 }}
          marginLeft={2}
          marginY={2}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            sx={{
              mb: { xs: 0, md: 1 },
              fontSize: { xs: 20, md: 30 },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
              transition: "font-size 0.3s ease",
            }}
          >
            <span>{title}</span>
            <Typography
              component="span"
              variant="body2"
              color="text.secondary"
              sx={{
                mb: { xs: 1, md: 0 },
                fontSize: { xs: 14, md: 18 },
                whiteSpace: { md: "nowrap" }, // Prevents wrapping on larger screens
                transition: "font-size 0.3s ease",
              }}
            >
              {formatShortDate(created_at)}
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: { xs: 14, md: 18 },
              transition: "font-size 0.3s ease",
            }}
          >
            {excerpt}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={`/blog/${id}`}>
            <Button
              sx={{
                fontSize: { xs: 14, md: 18 },
                transition: "font-size 0.3s ease",
              }}
            >
              Read More
            </Button>
          </Link>
        </CardActions>
      </Box>
    </Card>
  );
};

export default WootBlogPostCard;
