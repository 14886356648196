import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AppBarDesktopLogo from "./AppBarDesktopLogo";
import AppBarMobileLogo from "./AppBarMobileLogo";
import { Link } from "react-router-dom";
import { NavLink, PAGES } from "./config";
import { useAccount } from "../../contexts/AccountContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import WootButton from "../WootButton";
import { useState } from "react";

function AppBarNavContent() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBarDesktopLogo />
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          {PAGES.map((page) => (
            <Link
              to={page.to}
              key={page.label}
              style={{
                textDecoration: "none",
                color: "common.black",
              }}
            >
              <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                <Typography color="common.black">{page.label}</Typography>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
      <AppBarMobileLogo />
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {PAGES.map((page) => (
          <NavButton key={page.label} page={page} />
        ))}
      </Box>
    </>
  );
}

interface NavButtonProps {
  page: NavLink;
}

function NavButton({ page }: NavButtonProps) {
  const { account } = useAccount();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => setDialogOpen(false);

  if (!page.protected || account) {
    return (
      <Link
        to={page.to}
        key={page.label}
        style={{
          textDecoration: "none",
          color: "common.black",
        }}
      >
        <Button
          key={page.label}
          sx={{
            display: "block",
            fontSize: "24px",
            color: "common.black",
            borderRadius: "50px",
            px: 2,
          }}
        >
          {page.label}
        </Button>
      </Link>
    );
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">Requires sign in!</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <DialogContentText id="dialog-description">
            You must be signed in to access this page.
            <br />
            Would you like to sign in now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <WootButton
            onClick={handleDialogClose}
            wootStyles={{ variant: "destructive" }}
          >
            Cancel
          </WootButton>
          <Link to={page.to}>
            <WootButton
              onClick={handleDialogClose}
              wootStyles={{ variant: "secondary" }}
            >
              Sign in
            </WootButton>
          </Link>
        </DialogActions>
      </Dialog>
      <Link
        to={page.to}
        onClick={(e) => {
          e.preventDefault();
          setDialogOpen(true);
        }}
        style={{ textDecoration: "none", color: "common.black" }}
      >
        <Button
          sx={{
            display: "block",
            fontSize: "24px",
            color: "common.black",
            borderRadius: "50px",
            px: 2,
          }}
        >
          {page.label}
        </Button>
      </Link>
    </>
  );
}

export default AppBarNavContent;
