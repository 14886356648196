import React, { useState } from "react";
import {
  Box,
  TextField,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";

interface WootSignupFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootSignupForm({ emailState, menuState }: WootSignupFormProps) {
  const [email, setEmail] = emailState;
  const [password, setPassword] = useState("");
  const [above13, setAbove13] = useState(false);
  const [error, setError] = useState("");
  const [, setMenuState] = menuState;
  const [loading, setLoading] = useState(false);

  const handleSignup = async () => {
    setLoading(true);
    setError("");

    try {
      await axios.post(`${appConfig.warden_url}/signup`, {
        above_13: above13,
        email: email,
        password: password,
      });

      // If the signup is successful, switch to the Verify Email menu
      setMenuState(LoginMenu.VerifyEmail);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to sign up. Please try again.";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <WootForm
        onSubmit={handleSignup}
        sx={{ mt: 1 }}
        submitText="Sign up"
        submitDisabled={loading}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          disabled={loading}
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          disabled={loading}
          fullWidth
          name="password"
          placeholder="abc123"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <FormControlLabel
          label="I am above the age of 13"
          control={
            <Checkbox
              checked={above13}
              disabled={loading}
              onChange={(e) => setAbove13(e.target.checked)}
              color="secondary"
            />
          }
        />
      </WootForm>
      <Link
        to="#"
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          setMenuState(LoginMenu.Signin);
        }}
      >
        {"← Back to Login"}
      </Link>
    </Box>
  );
}

export default WootSignupForm;
