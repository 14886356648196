import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { useAccount } from "../../contexts/AccountContext";
import { useAuth } from "../../contexts/AuthContext";
import WootButton from "../WootButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useRedirect } from "../../utils/Redirect";

function WootCreateAccountForm() {
  const { setAccount } = useAccount();
  const { getAccessToken } = useAuth();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const doRedirect = useRedirect();
  const [loading, setLoading] = useState(false);

  const handleCreateAccount = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      const accessToken = await getAccessToken();
      await axios.post(
        `${appConfig.aura_url}/account/create`,
        {
          handle: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        }
      );

      doRedirect();
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to create account. Please try again.";
      setAccount(null);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Function to check username availability
  const checkUsernameAvailability = async (handle: string) => {
    setCheckingAvailability(true);
    setError(""); // Clear any previous errors

    try {
      const response = await axios.get(
        `${appConfig.aura_url}/account/check_available/${handle}`,
        {
          headers: {
            accept: "text/plain",
          },
        }
      );
      setIsAvailable(response.data === true);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to check username availability. Please try again.";
      setError(errorMessage);
    } finally {
      setCheckingAvailability(false);
    }
  };

  // Call availability check when the email (username) changes
  useEffect(() => {
    if (email) {
      const timeoutId = setTimeout(() => {
        checkUsernameAvailability(email);
      }, 500); // Debounce API calls to avoid too many requests
      return () => clearTimeout(timeoutId);
    }
  }, [email]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {success ? (
        <Box sx={{ textAlign: "center", marginTop: 2 }}>
          <h2>Congratulations!</h2>
          <p>Your account has been successfully created.</p>
          <Link to="/play">
            <WootButton wootStyles={{ variant: "secondary" }}>
              Play now
            </WootButton>
          </Link>
        </Box>
      ) : (
        <>
          Welcome to Woot Newts! You first need an account. Please choose a
          username!
          {error && <Alert severity="error">{error}</Alert>}
          <WootForm
            onSubmit={handleCreateAccount}
            sx={{ mt: 1 }}
            submitText="Create account"
            submitDisabled={!isAvailable || checkingAvailability || loading}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled={loading}
              fullWidth
              id="username"
              label="Username"
              name="username"
              placeholder="Billybob"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                sx: { color: "text.secondary" },
                endAdornment: (
                  <InputAdornment position="end">
                    {checkingAvailability ? (
                      <IconButton edge="end" disabled>
                        <MoreHorizIcon />
                      </IconButton>
                    ) : isAvailable ? (
                      <IconButton edge="end" disabled>
                        <CheckIcon color="success" />
                      </IconButton>
                    ) : (
                      <IconButton edge="end" disabled>
                        <CloseIcon color="error" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </WootForm>
        </>
      )}
    </Box>
  );
}

export default WootCreateAccountForm;
