import React from "react";
import { Box, BoxProps } from "@mui/material";

interface PlaceholderBoxProps extends BoxProps {
  width: number | string | { [key: string]: number | string }; // Support for responsive widths
  height: number | string | { [key: string]: number | string }; // Support for responsive heights
}

export default function PlaceholderBox({
  width,
  height,
  ...props
}: PlaceholderBoxProps) {
  return (
    <Box
      sx={{
        width: width,
        height: height,
        background:
          "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)", // Gradient effect
        backgroundSize: "200% 100%",
        animation: "loading 1.5s infinite", // Animation for loading effect
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px", // Optional rounded corners
        "@keyframes loading": {
          "0%": { backgroundPosition: "200% 0" },
          "100%": { backgroundPosition: "-200% 0" },
        },
      }}
      {...props}
    />
  );
}
