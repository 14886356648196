import React, { useEffect } from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import WootLogo from "../components/WootLogo";
import { useAccount } from "../contexts/AccountContext";
import axios, { AxiosError } from "axios";
import { appConfig } from "../config";
import { useAuth } from "../contexts/AuthContext";
import { useRedirect } from "../utils/Redirect";

function LogoutPage() {
  const { setAccount } = useAccount();
  const { setAccessToken, clearCookies } = useAuth();
  const doRedirect = useRedirect();

  // Logout if account is active
  useEffect(() => {
    const logout = async () => {
      try {
        await axios.get(`${appConfig.warden_url}/signout`, {
          withCredentials: true,
        });
      } catch (err) {
        const axiosError = err as AxiosError;
        const errorMessage =
          (axiosError.response?.data as string) ||
          "Failed to sign out. Please try again.";
        console.error(errorMessage);
      } finally {
        setAccount(null);
        setAccessToken(null);
        clearCookies();
      }

      doRedirect();
    };
    logout();
  }, [clearCookies, doRedirect, setAccessToken, setAccount]);

  return (
    <Container
      sx={{
        padding: "20px",
        textAlign: "center",
        marginTop: "50px",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "200px",
          margin: "0 auto",
        }}
      >
        <WootLogo />
      </Box>
      <CircularProgress
        size={100}
        color="secondary"
        sx={{ marginTop: "20px" }}
      />
      <Typography variant="h6" sx={{ marginTop: "20px" }}>
        Signing out...
      </Typography>
    </Container>
  );
}

export default LogoutPage;
