import { To } from "react-router-dom";

export interface NavLink {
  label: string;
  to: To;
  protected: boolean;
}

export const PAGES: NavLink[] = [
  { label: "Play", to: "/play", protected: false },
  {
    label: "News",
    to: "/home",
    protected: false,
  },
  { label: "Shop", to: "/shop", protected: true },
];
