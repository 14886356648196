import { useLocation, useNavigate } from "react-router-dom";

export function useRedirect() {
  const location = useLocation();
  const navigate = useNavigate();

  const doRedirect = () => {
    // Handle redirect logic
    const redirectUrl = new URLSearchParams(location.search).get("redirect");
    if (redirectUrl) {
      const decodedRedirectUrl = decodeURIComponent(redirectUrl);
      if (
        decodedRedirectUrl.startsWith("http://") ||
        decodedRedirectUrl.startsWith("https://")
      ) {
        window.location.href = decodedRedirectUrl;
      } else {
        console.log("Redirecting to referrer...");
        navigate(decodedRedirectUrl);
      }
    } else {
      navigate("/home");
    }
  };

  return doRedirect;
}
