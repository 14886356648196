import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAccount } from "../contexts/AccountContext";

const ProtectedRoute: React.FC = () => {
  const { account } = useAccount();
  const location = useLocation();

  const redirectUrl = encodeURIComponent(
    location.pathname + location.search + location.hash
  );
  const url = `/login?redirect=${redirectUrl}`;

  return account ? <Outlet /> : <Navigate to={url} replace />;
};

export default ProtectedRoute;
