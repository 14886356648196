import React, { createContext, useContext, useState } from "react";

interface AccountContextType {
  account: AccountData | null;
  setAccount: React.Dispatch<React.SetStateAction<AccountData | null>>;
}

export interface AccountData {
  id: string;
  handle: string;
  avatar_url: string | null;
  created_at: string;
  credits: number;
  discord_id: string | null;
  membership_expires: string | null;
  friends: number | null;
  followers: number | null;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [account, setAccount] = useState<AccountData | null>(null);

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
