import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useAccount } from "../../contexts/AccountContext";
import AppBarUserInfoDropdown from "./AppBarUserInfoDropdown";
import WootSignInButton from "../WootSignInButton";
import { useAuth } from "../../contexts/AuthContext";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";

function AppBarRightContent() {
  const { getAccessToken, setAccessToken, clearCookies } = useAuth();
  const { account, setAccount } = useAccount();
  const [loading, setLoading] = useState(false);

  // Automatic login on mount
  useEffect(() => {
    const startLogin = async () => {
      if (account) return;
      const accessToken = await getAccessToken();
      if (accessToken) {
        setLoading(true);
        console.log("Resuming login via app bar...");
        try {
          const response = await axios.get(
            `${appConfig.aura_url}/account/get`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setAccount(response.data);
        } catch (err) {
          const axiosError = err as AxiosError;
          const errorMessage =
            (axiosError.response?.data as string) ||
            "Failed to get account. Please try again.";
          console.error(errorMessage);
          // Logout
          try {
            await axios.get(`${appConfig.warden_url}/signout`, {
              withCredentials: true,
            });
          } catch (err) {
            const axiosError = err as AxiosError;
            const errorMessage =
              (axiosError.response?.data as string) ||
              "Failed to sign out. Please try again.";
            console.error(errorMessage);
          } finally {
            setAccount(null);
            setAccessToken(null);
            clearCookies();
          }
        } finally {
          setLoading(false);
        }
      }
    };
    startLogin();
  }, [getAccessToken, account, setAccount, setAccessToken, clearCookies]);

  if (account) {
    return <AppBarUserInfoDropdown account={account} />;
  } else if (loading) {
    return <CircularProgress size="1.5em" color="inherit" sx={{ mr: 2 }} />;
  } else {
    return <WootSignInButton wootStyles={{ variant: "primary" }} />;
  }
}

export default AppBarRightContent;
