import { Paper, Typography } from "@mui/material";
import WootBlogPostList from "../components/WootBlog/WootBlogPostList";

export default function HomePage() {
  return (
    <Paper
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: 0, lg: "20px" },
      }}
      square
    >
      <Typography variant="h4" component="h1" mb={2}>
        Recent News
      </Typography>
      <WootBlogPostList page_size={4} />
    </Paper>
  );
}
