import React from "react";
import WootButton, { WootButtonStyles } from "./WootButton";
import { Link, useLocation } from "react-router-dom";

interface WootSignInButtonProps {
  wootStyles?: WootButtonStyles;
}

function WootSignInButton({ wootStyles }: WootSignInButtonProps) {
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";
  const redirectComponent = encodeURIComponent(
    location.pathname + location.search + location.hash
  );
  const redirectUrl = !isLoginPage
    ? `/login?redirect=${redirectComponent}`
    : `/login`;

  return (
    <Link to={redirectUrl}>
      <WootButton wootStyles={wootStyles}>Sign in</WootButton>
    </Link>
  );
}

export default WootSignInButton;
