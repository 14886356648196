import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Box,
} from "@mui/material";
import WootButton from "../WootButton";
import { WootOffer } from "./types";
import PlaceholderBox from "../PlaceholderBox";

interface WootOfferCardProps {
  offer: WootOffer;
  onBuyClick: () => void;
}

const WootOfferCard = ({ offer, onBuyClick }: WootOfferCardProps) => {
  const [loading, setLoading] = useState(true); // Track loading state for image
  const price = (offer.default_price.unit_amount / 100).toFixed(2);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        textAlign: "left",
        minHeight: "150px",
        borderRadius: "10px",
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <CardMedia
          component="img"
          image={offer.images.length > 0 ? offer.images[0] : "/placeholder.svg"}
          alt={offer.name}
          sx={{
            width: { xs: 70, md: 140 },
            height: { xs: 70, md: 140 },
            marginLeft: 2,
            marginY: 2,
            objectFit: "contain",
            transition: "width 0.3s ease, height 0.3s ease",
            display: loading ? "none" : "block", // Hide image while loading
          }}
          onLoad={() => setLoading(false)} // Image loaded, stop showing placeholder
          onError={() => setLoading(false)} // In case of error, hide placeholder
        />
        {loading && (
          <PlaceholderBox
            width={{ xs: 70, md: 140 }}
            height={{ xs: 70, md: 140 }}
            marginLeft={2}
            marginY={2}
          />
        )}

        <Box
          sx={{
            margin: "auto 0",
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontSize: { xs: 20, md: 30 },
                transition: "font-size 0.3s ease",
              }}
            >
              {offer.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: { xs: 14, md: 18 },
                transition: "font-size 0.3s ease",
              }}
            >
              {offer.description}
            </Typography>
          </CardContent>
        </Box>
      </Box>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "stretch",
          padding: 2,
          marginLeft: "auto",
          height: "100%",
          minWidth: { xs: "100%", sm: "175px" }, // Adjust width for xs
          width: { xs: "100%", sm: "20%" }, // Adjust width for xs
        }}
      >
        <WootButton
          wootStyles={{ variant: "secondary" }}
          onClick={onBuyClick}
          sx={{
            width: "100%",
            height: { xs: "100%", sm: 115 },
            borderRadius: "10px",
            textAlign: "center",
            fontSize: { xs: "1em", md: "1.2em" },
            transition: "width 0.3s ease, font-size 0.3s ease",
          }}
        >
          Purchase
          <br />
          {(offer.default_price.currency === "usd" && `$${price}`) ||
            `${price} ${offer.default_price.currency}`}
        </WootButton>
      </CardActions>
    </Card>
  );
};

export default WootOfferCard;
