import React, { createContext, useContext, useState } from "react";
import axios, { AxiosError } from "axios";
import { appConfig } from "../config";

// Define the AuthContext type
interface AuthContextType {
  getAccessToken: () => Promise<string | null>;
  setAccessToken: (token: string | null) => void;
  rememberMe: boolean;
  clearCookies: () => void;
  setRememberMeCookie: (rememberMe: boolean) => void;
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Helper function to get cookie value
const getCookieValue = (name: string): string | null => {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

// Function to parse and check if JWT token is expired
const isTokenExpired = (token: string | null): boolean => {
  if (!token) return true;

  const [, payload] = token.split("."); // Extract payload from JWT token
  if (!payload) return true;

  const decoded = JSON.parse(atob(payload));
  const currentTime = Math.floor(Date.now() / 1000);
  return decoded.exp < currentTime; // Check expiration time
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getCookieValue("auth_access_token")
  );
  const [rememberMe, setRememberMe] = useState<boolean>(
    getCookieValue("stay_logged_in") === "true"
  );

  // Function to refresh access token
  const refreshAccessToken = async (): Promise<string | null> => {
    if (!rememberMe) {
      return null;
    }

    try {
      const response = await axios.post(
        `${appConfig.warden_url}/refresh`,
        {},
        {
          headers: { Accept: "application/json" },
          withCredentials: true, // Ensures cookies are sent and received
        }
      );

      const { access_token } = response.data;
      setAccessToken(access_token);
      return access_token;
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to refresh access token.";
      console.error(errorMessage);
      setAccessToken(null);
      return null;
    }
  };

  // Function to get the access token, refreshing if necessary
  const getAccessToken = async (): Promise<string | null> => {
    if (accessToken) {
      if (isTokenExpired(accessToken)) {
        console.warn("Access token is expired.");
        return null;
      }
      return accessToken;
    } else if (rememberMe) {
      return await refreshAccessToken(); // Refresh if token is missing or expired
    }
    return null;
  };

  const setRememberMeCookie = (rememberMe: boolean): void => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 30); // Set expiration to 30 days from now
    // Add the cookie with necessary options
    document.cookie = `stay_logged_in=${rememberMe}; expires=${expires.toUTCString()}; path=/; SameSite=Lax; Secure`;
    setRememberMe(rememberMe);
  };

  const clearCookies = (): void => {
    // Clear authentication cookies by expiring them immediately.
    document.cookie = `stay_logged_in=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;
    setRememberMe(false);
    console.log("Cookie cleared.");
  };

  return (
    <AuthContext.Provider
      value={{
        getAccessToken,
        setAccessToken,
        rememberMe,
        setRememberMeCookie,
        clearCookies,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
