import React, { useState } from "react";
import { Box, TextField, Alert, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";
import WootLoadingLink from "../WootLoadingLink";
import WootButton from "../WootButton";

interface WootEmailVerificationFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootEmailVerificationForm({
  emailState,
  menuState,
}: WootEmailVerificationFormProps) {
  const [email, setEmail] = emailState;
  const [, setMenuState] = menuState;
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [verifiedSuccess, setVerifiedSuccess] = useState(false);
  const [verificationLoading, setVerificationLoading] =
    useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);

  const handleVerify = async () => {
    setVerificationLoading(true);
    setError("");
    setVerifiedSuccess(false);
    setResendSuccess(false);

    try {
      await axios.get(`${appConfig.warden_url}/verify-email`, {
        params: { code, email },
      });

      setVerifiedSuccess(true);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to verify email. Please try again.";
      setError(errorMessage);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setResendLoading(true);
    setError("");
    setResendSuccess(false);

    try {
      await axios.post(
        `${appConfig.warden_url}/resend-email`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      setResendSuccess(true);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to send email for verification. Please try again.";
      setError(errorMessage);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {(verifiedSuccess && (
        <Alert severity="success">Email verification successful!</Alert>
      )) ||
        (resendSuccess && <Alert severity="success">Email resent.</Alert>) || (
          <Typography color="common.black" sx={{ textAlign: "center" }}>
            Check your email for a verification code.
          </Typography>
        )}
      {error && <Alert severity="error">{error}</Alert>}
      {!verifiedSuccess && (
        <>
          <WootForm
            onSubmit={handleVerify}
            sx={{ mt: 1 }}
            submitText="Verify"
            submitDisabled={verificationLoading}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled={verificationLoading}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              placeholder="john@smith.com"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ sx: { color: "text.secondary" } }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled={verificationLoading}
              fullWidth
              name="code"
              placeholder="123456"
              label="Code"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              InputProps={{ sx: { color: "text.secondary" } }}
            />
          </WootForm>
          <WootLoadingLink
            to="#"
            onClick={handleResendVerification}
            loading={resendLoading}
          >
            Resend verification code
          </WootLoadingLink>
        </>
      )}
      {verifiedSuccess && (
        <WootButton
          sx={{
            mt: 1,
          }}
          onClick={() => {
            setMenuState(LoginMenu.Signin);
          }}
          wootStyles={{
            variant: "secondary",
          }}
        >
          Go to Login
        </WootButton>
      )}
    </Box>
  );
}

export default WootEmailVerificationForm;
