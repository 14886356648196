import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Alert,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";
import { useAccount } from "../../contexts/AccountContext";
import { useRedirect } from "../../utils/Redirect";

interface WootSigninFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootSigninForm({ emailState, menuState }: WootSigninFormProps) {
  const { getAccessToken, setAccessToken, setRememberMeCookie, clearCookies } =
    useAuth();
  const { setAccount } = useAccount();
  const [email, setEmail] = emailState;
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [, setMenuState] = menuState;
  const hasAttemptedResume = useRef(false);
  const [resuming, setResuming] = useState(false);
  const [loading, setLoading] = useState(false);
  const doRedirect = useRedirect();

  const attemptResume = useCallback(async () => {
    if (hasAttemptedResume.current) return; // Prevent multiple calls
    hasAttemptedResume.current = true; // Mark as called
    console.log("Attempting to resume...");
    const accessToken = await getAccessToken();
    if (!accessToken) {
      return;
    }
    setResuming(true);
    try {
      const response = await axios.get(`${appConfig.aura_url}/account/get`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setAccount(response.data);
      doRedirect();
    } catch (err) {
      const axiosError = err as AxiosError;
      const status = axiosError.response?.status;
      setAccount(null);
      // 400 = Account does not exist
      if (status === 400) {
        setMenuState(LoginMenu.CreateAccount);
      } else {
        console.warn(
          (axiosError.response?.data as string) ||
            "Failed to resume account. Please try again."
        );
        clearCookies();
      }
    }
  }, [clearCookies, doRedirect, getAccessToken, setAccount, setMenuState]);

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    var token;
    try {
      const response = await axios.post(
        `${appConfig.warden_url}/signin`,
        { email, password },
        { withCredentials: true }
      );
      const { access_token } = response.data;
      setAccessToken(access_token);
      token = access_token;
    } catch (err) {
      const axiosError = err as AxiosError;
      const status = axiosError.response?.status;

      // 409 CONFLICT if the user has not been verified by email.
      if (status === 409) {
        setMenuState(LoginMenu.VerifyEmail);
      } else {
        const errorMessage =
          (axiosError.response?.data as string) ||
          "Failed to sign in. Please try again.";
        setError(errorMessage);
        clearCookies();
      }
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${appConfig.aura_url}/account/get`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setAccount(response.data);
      setRememberMeCookie(rememberMe);
      doRedirect();
    } catch (err) {
      const axiosError = err as AxiosError;
      const status = axiosError.response?.status;

      setAccount(null);
      // 400 = Account does not exist
      if (status === 400) {
        setMenuState(LoginMenu.CreateAccount);
        setRememberMeCookie(rememberMe);
      } else {
        setError(
          (axiosError.response?.data as string) ||
            "Failed to retrieve account. Please try again."
        );
        clearCookies();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    attemptResume();
  }, [attemptResume]);

  if (resuming) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {resuming && (
          <>
            <Typography>Resuming session...</Typography>
            <CircularProgress sx={{ mt: 2 }} />
          </>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">Login successful!</Alert>}
      <WootForm
        onSubmit={handleLogin}
        sx={{ mt: 1 }}
        submitText="Login"
        submitDisabled={loading}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          disabled={loading}
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="abc123"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={loading}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <FormControlLabel
          label="Remember me for 30 days"
          control={
            <Checkbox
              checked={rememberMe}
              disabled={loading}
              onChange={(e) => setRememberMe(e.target.checked)}
              color="secondary"
            />
          }
        />
      </WootForm>
      <Grid container>
        <Grid item xs>
          <Link
            to="#"
            style={{
              textDecoration: "none",
            }}
            onClick={() => setMenuState(LoginMenu.ForgotPasswordForm)}
          >
            {"Forgot password?"}
          </Link>
        </Grid>
        <Grid item>
          <Link
            to="#"
            style={{
              textDecoration: "none",
            }}
            onClick={() => setMenuState(LoginMenu.Signup)}
          >
            {"No account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WootSigninForm;
