import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
  Box,
  Paper,
  Alert,
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WootButton from "../components/WootButton";
import { Link } from "react-router-dom";
import WootForm from "../components/WootForm/WootForm";
import axios, { AxiosError } from "axios";
import { useAuth } from "../contexts/AuthContext";
import { AccountData, useAccount } from "../contexts/AccountContext";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { appConfig } from "../config";
import { formatMembershipDate, formatShortDate } from "../utils/Dates";

enum TransactionStatus {
  Pending = "pending",
  Completed = "completed",
  Cancelled = "cancelled",
}

export interface Transaction {
  account_id: string;
  amount: number;
  checkout_session_id: string;
  created_at: string;
  currency: string;
  id: string;
  product_type: string;
  status: TransactionStatus;
  unit_amount: number;
}

export default function SettingsPage() {
  const { getAccessToken } = useAuth();
  const { account } = useAccount();

  // Transactions
  const [transactions, setTransactions] = useState<Transaction[] | null>(null);
  const [transactionsError, setTransactionsError] = useState("");
  const [transactionsLoading, setTransactionsLoading] = useState(false);

  // Change password
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

  // External connections
  const isDiscordLinked: boolean = Boolean(account?.discord_id);

  // Get payment history on load
  const getTransactions = useCallback(async () => {
    setTransactions(null);
    setTransactionsError("");
    setTransactionsLoading(true);

    try {
      const response = await axios.get(
        `${appConfig.aura_url}/account/transactions`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${await getAccessToken()}`,
          },
        }
      );
      setTransactions(response.data);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to fetch transaction history. Please try again.";
      setTransactionsError(errorMessage);
    } finally {
      setTransactionsLoading(false);
    }
  }, [getAccessToken]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const handleChangePassword = async () => {
    setChangePasswordError("");
    setChangePasswordSuccess(false);

    // Validate input
    if (!password || !newPassword || !confirmNewPassword) {
      setChangePasswordError("All fields are required.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setChangePasswordError("New password and confirmation do not match.");
      return;
    }

    try {
      await axios.post(
        `${appConfig.warden_url}/change-password`,
        {
          new_password: newPassword,
          previous_password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${await getAccessToken()}`,
          },
        }
      );
      setChangePasswordSuccess(true);
      setPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        "Failed to change password. Please try again.";
      setChangePasswordError(errorMessage);
    }
  };

  const handleViewAccountData = (account: AccountData) => {
    alert(JSON.stringify(account, null, 2)); // Display account data in an alert
  };

  return (
    <Paper
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: 0, lg: "20px" },
      }}
      square
    >
      <Typography variant="h4" component="h1" mb={2}>
        Settings
      </Typography>

      {/* Columns for Membership and Credits */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="div" sx={{ fontSize: "1.5rem" }}>
            Your Membership
          </Typography>
          {!account ? (
            <CircularProgress size={"1rem"} />
          ) : (
            <Typography
              component="span"
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                fontSize: "1rem",
                lineHeight: "1",
              }}
            >
              {account.membership_expires ? (
                <>
                  <CheckIcon color="success" />
                  {`Active until ${formatMembershipDate(
                    account.membership_expires
                  )}`}
                </>
              ) : (
                <>
                  <CloseIcon color="error" />
                  Not active
                </>
              )}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="div" sx={{ fontSize: "1.5rem" }}>
            Your Credits
          </Typography>
          {!account ? (
            <CircularProgress size={"1rem"} />
          ) : (
            <Typography
              component="span"
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                fontSize: "1rem",
                lineHeight: "1",
              }}
            >
              <MonetizationOnIcon sx={{ marginRight: "0.25em" }} />
              {account.credits}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Change Password Accordion */}
      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {changePasswordError && (
            <Alert severity="error">{changePasswordError}</Alert>
          )}
          {changePasswordSuccess && (
            <Alert severity="success">Password changed successfully!</Alert>
          )}
          <WootForm
            onSubmit={handleChangePassword}
            submitText="Change Password"
          >
            <TextField
              fullWidth
              required
              type="password"
              label="Current Password"
              variant="outlined"
              margin="normal"
              name="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              fullWidth
              required
              type="password"
              label="New Password"
              variant="outlined"
              margin="normal"
              name="new-password"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              fullWidth
              required
              type="password"
              label="Confirm New Password"
              variant="outlined"
              margin="normal"
              name="confirm-new-password"
              id="confirm-new-password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </WootForm>
        </AccordionDetails>
      </Accordion>

      {/* External Connections Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>External Connections</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Discord Integration</Typography>
            {isDiscordLinked ? (
              <Typography
                color="success.main"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CheckIcon sx={{ mr: 1 }} /> Linked
              </Typography>
            ) : (
              <Typography
                color="error.main"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CloseIcon sx={{ mr: 1 }} /> Linked
              </Typography>
            )}
          </Box>
          {isDiscordLinked ? (
            <Link target="_blank" to="/unlink-discord">
              <WootButton
                wootStyles={{ variant: "destructive" }}
                fullWidth
                sx={{ mt: 2 }}
              >
                {"Unlink Discord"}
              </WootButton>
            </Link>
          ) : (
            <Link target="_blank" to="/link-discord">
              <WootButton
                wootStyles={{ variant: "secondary" }}
                fullWidth
                sx={{ mt: 2 }}
              >
                {"Link Discord"}
              </WootButton>
            </Link>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Transactions Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Transaction History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {transactionsLoading && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <CircularProgress size={"1rem"} />
              <Typography
                variant="body2"
                color="textSecondary"
                textAlign="center"
                alignItems="middle"
                ml={1}
              >
                {" Loading..."}
              </Typography>
            </Box>
          )}
          {transactionsError && (
            <Alert severity="error">{transactionsError}</Alert>
          )}
          {transactions && transactions.length > 0 && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="transaction history table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Product Type</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell component="th" scope="row">
                        {transaction.product_type.toUpperCase()}
                      </TableCell>
                      <TableCell align="right">
                        {transaction.currency.toUpperCase()}{" "}
                        {(transaction.unit_amount / 100).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {transaction.status.toUpperCase()}
                      </TableCell>
                      <TableCell align="right">
                        {formatShortDate(transaction.created_at)}
                      </TableCell>
                      <TableCell align="right">
                        {transaction.id.slice(-7).toUpperCase()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {transactions && transactions.length === 0 && (
            <Typography
              variant="body2"
              color="textSecondary"
              textAlign="center"
              mt={2}
            >
              No transaction history available.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Data & Privacy Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Data & Privacy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography>
              We are in the process of receiving COPPA and GDPR compliance
              certification from third party auditors. For now, you can find our
              cookie policy and privacy notice at the bottom of our website. You
              may always export your data using the button below.
            </Typography>
            {(account && (
              <WootButton
                fullWidth
                wootStyles={{ variant: "secondary" }}
                sx={{ mt: 2 }}
                onClick={() => {
                  handleViewAccountData(account);
                }}
              >
                View My Data
              </WootButton>
            )) || (
              <WootButton
                disabled
                fullWidth
                wootStyles={{ variant: "secondary" }}
                sx={{ mt: 2 }}
              >
                {"Loading..."}
              </WootButton>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Logout Button */}
      <Link to="/logout">
        <WootButton
          fullWidth
          sx={{
            mt: 4,
            alignSelf: "center",
          }}
          wootStyles={{ variant: "destructive" }}
        >
          Logout
        </WootButton>
      </Link>
    </Paper>
  );
}
